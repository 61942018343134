/************ Custom Font ************/
@font-face {
  font-family: 'Fasthand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fasthand/v30/0yb9GDohyKTYn_ZEERkiaE0GrQ.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+19E0-19FF, U+200C-200D, U+25CC;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #1E1E1E !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

/*
nav.navbar a.navbar-brand {
  width: 9%;
}
*/

nav.navbar a.navbar-brand {
  width: 25%;
  /* This is the default rule, which applies on small screens */
}

@media (min-width: 768px) {
  nav.navbar a.navbar-brand {
    width: 160px;
    height: 60px;
    /* This rule overrides the default rule on screens that are at least 768px wide */
  }
}

div.navbar-text {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.social-icon {
  display: flex;
  /* Change this to flex */
  flex-direction: row;
  /* Add this line */
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 12px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

@media (min-width: 700px) {
  .navbar-text button {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 10px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
  }
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #77d4fc;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

/************ Banner Css ************/
.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 250px;
  padding: 20px 0 20px 0;
}

.newsletter-bx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.newsletter-bx .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}



/* Mobile styles */
@media (max-width: 768px) {
  .banner p {
    font-size: 20px !important;
  }
}

@media (min-width: 780px) {
  .banner h1 {
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
  }
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  padding-top: 25px;
  padding-bottom: 5px;
  font-size: 30px;
}

.newsletter-bx button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 10px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
  color: #77d4fc;
}

/* Mobile Style */
.transparent-div {
  position: relative;
  height: 250px;
  width: 100%;
  background: rgba(30, 30, 30, 0.2);
  margin-top: -180px;
  color: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-left: auto;
  margin-right: auto; */
}

.container-fluid {
  padding: 0;
  /* Remove padding from the container */
}

@media (min-width: 768px) {
  .transparent-div {
    position: relative;
    height: 250px;
    width: 70vw;
    background: rgba(30, 30, 30, 0.2);
    /* Semi-transparent black background */
    border-radius: 20px;
    /* Rounded corners */
    padding: 20px;
    margin-top: -100px;
    color: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    /* To contain the gradient circles */
  }
}

/* Gradient circles */
.transparent-div::before,
.transparent-div::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(119, 212, 252, 0.2);
  /* Initial faint color */
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 0 80px 40px rgba(119, 212, 252, 0.4);
  /* Create a fading effect */
}

.transparent-div::before {
  top: 100px;
  left: 70px;
}

.transparent-div::after {
  bottom: 50px;
  right: 100px;
}

/* Ensure text content is above the gradient circles */
.transparent-div>* {
  position: relative;
  z-index: 2;
}

/* General styles for the GIFs container */
.background-gifs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 0;
  transition: opacity 1s;
  overflow: hidden;
  /* Ensure content stays within the div */
}

/* Animation keyframes for moving GIFs from bottom to top */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 0.4;
  }
}

/* Initially hide GIFs */
.background-gifs img {
  max-width: 250px;
  max-height: 300px;
  margin: 5px;
  border-radius: 10px;
  opacity: 0;
  transition: transform 1s, opacity 1s;
  /* Ensure opacity transitions smoothly */
  transform: translateY(100%);
}

/* Apply the slide-up animation */
.background-gifs.animate img {
  animation: slideUp 2s forwards;
}

/* Mobile styles */
@media (max-width: 768px) {
  .background-gifs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1px;
    justify-content: center;
    align-items: center;
    height: auto;
    /* Adjust height if needed */
  }

  .background-gifs img {
    max-width: 100px;
    /* Adjust size for mobile */
    max-height: 120px;
    /* Adjust size for mobile */
    margin: 5px;
  }
}

/* Old Animation */

/*
.background-gifs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgb(28, 28, 28);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 0;
  transition: opacity 1s;
}

.background-gifs img {
  max-width: 250px;
  max-height: 300px;
  margin: 10px 10px;
  border-radius: 10px;
  opacity: 0.4;
transition: transform 1s;
}

.background-gifs img.stay-put {
  transform: translateY(0);
}


@media (max-width: 768px) {
  .background-gifs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2px;
    justify-content: center;
    align-items: center;
    height: auto;
  
  }

  .background-gifs img {
    max-width: 110px;
  
    max-height: 120px;
   
    margin: 1px;
  }
}

*/

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  /* background-image: url('./assets/img/footer-bg.png'); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #0c0c0c;
  padding: 5px 10px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: auto;

}




.new-email-bx input {
  width: 100%;
  color: #f4f4f4;
  font-weight: 600;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 18px;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-email-bx button span {
  z-index: 3;
  position: relative;
}

.new-email-bx button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #77d4fc;
  border-radius: 18px;
  left: 0;
  top: 0;
  z-index: -1;
  transition: width 0.3s ease-in-out;
}

.new-email-bx button:hover {
  color: #0c0c0c;
  /* Change text color to match the background color */
}

.new-email-bx button:hover::before {
  width: 100%;
  /* Expand the pseudo-element to cover the button */
}

.check-icon {
  height: 35px;
  width: 35px;
  transition: filter 0.3s ease-in-out;
}

.check-icon:hover {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}